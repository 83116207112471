.main-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  height: 600px;
  background: #000;

  .message {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 50px;
    font-family: 'Bungee', cursive;
    font-size: 40px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.95);
    color: #ff2626;
  }

  ul {
    list-style: none;

    li {
      position: relative;
      color: #fff;
      font-family: 'Bungee', cursive;
      font-size: 20px;
      line-height: 42px;
      text-transform: uppercase;

      &.disabled {
        color: #212121;
      }

      &.selected {
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: -50px;
          width: 42px;
          height: 42px;
          background: url('../../assets/img/tank.svg') 0 0 no-repeat;
          transform: rotate(90deg);
        }
      }
    }
  }
}