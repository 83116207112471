* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #404040;
}

#root {
  display: flex;
  height: 100%;
}