.obstacle {
  position: absolute;
  z-index: 200;
  display: block;

  &.brick {
    background: url('./../../assets/img/brick.svg') 0 0 no-repeat;
  }

  &.metal {
    background: url('./../../assets/img/metal.svg') 0 0 no-repeat;
  }

  &.forest {
    background: url('./../../assets/img/forest.svg') 0 0 no-repeat;
  }

  &.water {
    z-index: 50;
    background: url('./../../assets/img/water.svg') 0 0 no-repeat;
  }

  &.transparent {
    background: #404040;
  }
}